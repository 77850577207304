import React from "react";
import { rgba } from "polished";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { FaWhatsapp, FaTelegramPlane, FaInstagram } from "react-icons/fa";

import { Section, Title, Text } from "../../components/Core";
import { device } from "../../utils";

const ContactCard = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
  margin-top: 3rem;
  @media ${device.lg} {
    margin-top: 250px;
  }
`;

const Contact = ({ hero = true, bg = "dark", ...rest }) => {
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row >
            <Col lg="12" className="text-center">
                <Title color="light" variant="secSm" mb="2rem">
                  Способы связи на ваш выбор
                </Title>
            </Col>
          </Row><Row>
            <Col lg="3">
              <ContactCard className="p-5 text-center">
                <a href="https://www.instagram.com/lerusch/" target="_blank" className="font-weight-bold">
                  <Title variant="hero" className="text-center" color="light"><FaInstagram/></Title>
                </a>
                <Text color="light">Instagram</Text>
              </ContactCard>
            </Col>
            <Col lg="3">
              <ContactCard className="p-5 text-center">
                <a href="https://t.me/Lerusch" target="_blank" className="font-weight-bold">
                  <Title variant="hero" className="text-center" color="light"><FaTelegramPlane></FaTelegramPlane></Title>
                </a>
                <Text color="light">Telegram</Text>
              </ContactCard>
            </Col>
            <Col lg="3">
              <ContactCard className="p-5 text-center">
                <a href="https://wa.me/31649297880" target="_blank" className="font-weight-bold">
                  <Title variant="hero" className="text-center" color="light"><FaWhatsapp></FaWhatsapp></Title>
                </a>
                <Text color="light">WhatsApp</Text>
              </ContactCard>
            </Col>
            <Col lg="3">
              <ContactCard className="p-5 text-center">
                <a href="mailto:valorie.copy@gmail.com" target="_blank" className="font-weight-bold">
                  <Title variant="hero" className="text-center" color="light"><i class="icon icon-email-84"></i></Title>
                </a>
                <Text color="light">E-mail</Text>
              </ContactCard>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Contact;
