import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text } from "../../components/Core";
import imgPhoto from "../../assets/image/jpg/portfolio-about.jpg";

const LinkSocial = styled.a`
  color: ${({ theme }) => theme.colors.light} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1.63px;
  transition: 0.4s;
  &:hover {
    color: ${({ theme }) => theme.colors.light} !important;
    transform: translateY(-5px);
  }
`;

const AboutImg = styled.img`
border-radius: 2rem;
box-shadow: 0 20px 60px 0 rgb(244 151 190 / 80%);`

const ListItem = styled.li`
list-style: circle;
`

const TextLink = styled.a`
  color: rgba(22,28,45,0.7) !important;
  font-weight:bold;
`

const About = ({ hero = true, bg = "dark", ...rest }) => {
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>

          <Row className="align-items-center">
            <Col lg="6">
              <div>
                <AboutImg src={imgPhoto} alt="folio" className="img-fluid" />
              </div>
            </Col>
            <Col lg="6">
              <div className="pl-lg-4 pt-lg-0">
                <Title variant="secSm">
                  Валерия Бутовецкая
                </Title>
                <Text className="font-weight-bold">
                  Копирайтер
                </Text><br />
                <Text variant="p" className="font-weight-bold" >
                  Образование:
                </Text>
                <ol>
                  <ListItem><Text variant="p" >
                    Специалист по маркетинговым коммуникациям. Роттердамский институт коммуникации, медиа и информационных технологий.
                  </Text></ListItem>
                  <ListItem><Text variant="p" >
                    Менеджер социально-культурной деятельности. Самарская государственная академия культуры и искусств.
                  </Text></ListItem>
                </ol>
                <Text variant="p" className="font-weight-bold">
                  Опыт работы:
                </Text>
                <ol>
                  <ListItem><Text variant="p" >
                    Соучредитель стартапа. Мобильное приложение EATELLA.
                  </Text></ListItem>
                  <ListItem><Text variant="p" >
                    Онлайн-маркетолог. Туристическая платформа TravelBird.
                  </Text></ListItem>
                  <ListItem><Text variant="p" >
                    Ассистент отдела маркетинга и PR. Независимый кинодистрибьютор Amstelfilm.
                  </Text></ListItem>
                </ol>
                <Text variant="p" className="font-weight-bold">
                  Обучение в области контент-маркетинга и коммерческого копирайтинга:
                </Text>
                <ol>
                <ListItem><Text variant="p" >
                Интенсив Катерины Яшиной «КнигоLove» — как создавать книги. Ноябрь 2022.
                  </Text>
                  <TextLink a href="https://drive.google.com/file/d/12bk1Eho2m7SJ4eEpZF5NcFHpJ4Yhxa6a/view" target="_blank" >Сертификат.</TextLink>
                </ListItem>
                <ListItem><Text variant="p" >
                Интенсив Катерины Яшиной «Тайны сторителлинга». Август 2022. 
                  </Text>
                  <TextLink a href="https://drive.google.com/file/d/1NyppGH_rldqFMmedrX-QxYL4ramITzXk/view" target="_blank" >Сертификат.</TextLink>
                </ListItem>
                <ListItem><Text variant="p" >
                Двухсерийный курс по коммерческому копирайтингу и прокачиванию навыков автора Евгении Карелиной и Алеси Шишкиной. Апрель-Май 2022.
                  </Text>
                </ListItem>
                <ListItem><Text variant="p" >
                  Курс по актуальным методам вовлечения в текст «Любовь и тексты» Катерины Яшиной. Октябрь 2021.
                  </Text>
                  <TextLink a href="https://drive.google.com/file/d/1SQR2T4GegR4DfIdpJgE8nUOGuwPtkbzz/view" target="_blank" >Сертификат.</TextLink>
                </ListItem>


                  <ListItem><Text variant="p" >
                    Квест-курс по копирайтингу, маркетингу и психологии Катерины Яшиной «Ешь.Пиши.Богатей». Сентябрь 2021.

                  </Text>
                    <TextLink a href="https://drive.google.com/file/d/1WWwjbKYtD1kNiWRMMiTwogRerJ2FdEPg/view?usp=sharing" target="_blank" > Сертификат.</TextLink></ListItem>

                    <ListItem><Text variant="p" >
                    Практикум «Как зацепить текстом» Лены Пиннивух. Июнь 2021. </Text>
                    <TextLink href="https://drive.google.com/file/d/1GU-cCs3DYWosYw-7IKAnGAVAFHRzTdfp/view?usp=sharing" target="_blank" > Сертификат.</TextLink></ListItem>

                    <ListItem><Text variant="p" >
                    Практикум «Пиши регулярно» Марии Апакидзе. Май 2021. </Text>
                  </ListItem>
                  <ListItem><Text variant="p" >
                    Курс-интенсив «Системный контент» агентства поиска идей для бизнеса «Помозгуем». Ноябрь 2020. </Text></ListItem>

                  <ListItem>
                    <Text variant="p">
                      Курс «Профессия: Контент-копирайтер» агентства поиска идей для бизнеса «Помозгуем». Август 2020.
                      <TextLink href="https://drive.google.com/file/d/1pdYo-KAneGdg6olTNt9d9_mW75Svvz1V/view?usp=sharing" target="_blank" > Сертификат.</TextLink>
                    </Text>
                  </ListItem>

                  

                </ol>



                <Text variant="p" className="font-weight-bold">
                  Иностранные языки:
                </Text>
                <ol>
                  <ListItem><Text variant="p" >
                    Английский
                  </Text></ListItem>
                  <ListItem><Text variant="p" >
                    Нидерландский
                  </Text></ListItem>
                </ol>


              </div>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col className="p-5">
              <Text variant="handwriting" >
                Живу в Нидерландах. Отличаюсь страстной любовью к созданию уникальных текстов. Обожаю книги, психологию и Дизайн Человека. Питаю нежные чувства к перемещениям по миру и поискам вдохновения.
              </Text>

            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default About;
